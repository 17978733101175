  
  .call-to-action button {
    padding: 0.75rem 2rem;
    color: black;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    max-width: 360px;
  }

    .call_to_action_image img {
      width: 70%;
      margin: 0 auto;
    }
  