.App {
  text-align: center;
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Customizing Tailwind CSS colors */
.bg-main-color {
    background-color: #3137ff;
}
.bg-second-color {
  background-color: #D0FC54;
}
.bg-third-color {
  background-color: #222222;
}


.text-main-color {
    color: #3137ff;
}
.text-second-color {
  color: #D0FC54;
}

.border-main-color {
    border-color: #3137ff;
}
.border-second-color {
  color: #D0FC54;
}

.line-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.BlogContent {
  font-family: source-serif-pro, Georgia, Cambria, "Times New Roman", Times, serif;
  line-height: 40px;
}

.BlogContent h2{
  font-size: 32px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
}
.BlogContent h3{
  font-size: 30px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
}
.BlogContent h4{
  font-size: 22px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
}
.BlogContent h5{
  font-size: 20px;
  font-weight: bold;
  margin-top: 6px;
  margin-bottom: 6px;
}
.BlogContent h6{
  font-size: 18px;
  font-weight: bold;
  margin-top: 6px;
  margin-bottom: 6px;
}
.BlogContent p{
  font-size: 22px;
}

@media only screen and (max-width: 600px) {
  .BlogContent {
    font-family: source-serif-pro, Georgia, Cambria, "Times New Roman", Times, serif;
    line-height: 25px;
  }
  
  .BlogContent h2{
    font-size: 16px;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .BlogContent h3{
    font-size: 16px;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .BlogContent h4{
    font-size: 16px;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .BlogContent h5{
    font-size: 16px;
    font-weight: bold;
    margin-top: 3px;
    margin-bottom: 3px;
  }
  .BlogContent h6{
    font-size: 18px;
    font-weight: bold;
    margin-top: 3px;
    margin-bottom: 3px;
  }
  .BlogContent p{
    font-size: 16px;
  }
}

