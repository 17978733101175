/* src/pages/Signup.css */
.signup-page {
    font-family: Arial, sans-serif;
    color: #333;
    background-color: white;
  }
  
  .signup-left {
    padding: 2rem;
    padding-bottom: 20px;
  }
  
  .signup-left h2 {
    /* font-size: 44px; */
    font-weight: bold;
    margin-bottom: 1rem;
    max-width: 769px;
    text-align: left;
    /* line-height: 40px; */

  }
  
  .signup-left ul {
    list-style: none;
    padding-left: 0;
  }
  
  .signup-left ul li {
    margin-bottom: 0rem;
    font-size: 15px;
    position: relative;
    padding-left: 1.5rem;
    font-weight: normal;
    text-align: left;
    line-height: 30px;
  }
  
  .signup-left ul li::before {
    content: '✓';
    color: #007BFF;
    position: absolute;
    left: 0;
    top: 0;
  }
  
  .signup-right {
    padding: 2rem;
    color: #fff;
    /* margin-top: -180px; */
    /* padding-top: 180px; */
    padding-bottom: 90px;
    padding-right: 80px;
    background-image: url("../assets/illustration/wave_2.png");
  }
  
  .signup-right h2 {
    /* font-size: 56px; */
    font-weight: bold;
    /* line-height: 64px; */
    margin-bottom: 1rem;
    letter-spacing: 0.2%;
    text-align: left;
  }
  
  .signup-form {
    display: flex;
    flex-direction: column;
  }
  
  .signup-form input,
  .signup-form select {
    padding: 0.75rem;
    margin-bottom: 1rem;
    border: none;
    border-radius: 4px;
  }
  
  .signup-form input[type="text"],
  .signup-form input[type="email"] {
    font-size: 1rem;
  }
  
  .signup-form select {
    font-size: 1rem;
  }
  
  .checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .checkbox input {
    margin-right: 0.5rem;
  }
  
  .join-btn {
    padding: 0.75rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1.25rem;
    color: black;
    margin-bottom: 1rem;
  }
  
  .separator {
    text-align: center;
    margin: 1rem 0;
  }
  
  .google-signup-btn {
    padding: 0.75rem;
    background-color: #fff;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
  }
  
