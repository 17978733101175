/* src/pages/ThankYou.css */
.thankyou-page {
    font-family: Arial, sans-serif;
    color: #333;
  }
  
  .thankyou-header {
    text-align: center;
    padding: 1rem;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .thankyou-logo {
    height: 50px;
  }
  
  .thankyou-content {
    padding: 4rem 2rem;
    background: linear-gradient(to bottom, #007BFF, #00A3FF);
    color: #fff;
    text-align: center;
  }
  
  .thankyou-message {
    margin-bottom: 2rem;
  }
  
  .thankyou-message h2 {
    font-size: 44px;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .thankyou-message p {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  
  .thankyou-illustration {
    text-align: center;
  }
  
  .thankyou-illustration img {
    max-width: 100%;
    height: auto;
  }
  
  .thankyou-footer {
    text-align: center;
    padding: 2rem;
    background-color: #333;
    color: #fff;
  }
  
  .thankyou-footer h3 {
    margin-bottom: 1rem;
  }
  
  .thankyou-footer p {
    margin-bottom: 1rem;
  }
