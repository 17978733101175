.footer {
    text-align: center;
    /* padding: 50px 150px; */
    background-color: #333;
    color: #fff;
  }
  
  .footer .p_1 {
    /* font-size: 54px; */
    font-weight: bold;
    max-width: 563px;
    text-align: left;
    margin-bottom: 20px;

  }
  .footer .p_2 {
    margin-bottom: 1rem;
    text-align: left;
    font-size: 18px;
    line-height: 30px;
    font-weight: normal;
    max-width: 563px;
  }

  .contact_us_block {
    text-align: left;
    width: 326.13px;
  }
  
  .contact-btn {
    padding: 0.75rem 2rem;
    background-color: #D0FC54;
    color: #000000;
    border: none;
    cursor: pointer;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    width: 140px;
    height: 45px;
  }

  .mail_link {
    font-size: 15px;
    font-weight: normal;
    line-height: 35px;
  }
  
  .social-icons {
    text-align: left;
    margin: 0;
    display: flex;
  }

  .social-icons a {
    margin: 0 0rem;
    text-decoration: none;
    margin-right: 26px;
    padding: 0;
  }
  .social-icons a img {
    width: 20px;
    height: 20px;
  }