.features-comparison {
    /* padding: 4rem 4rem; */
    background-color: white;
  }
  .features-comparison h2 {
    text-align: center;
    /* font-size: 51px; */
    line-height: 64px;
    font-weight: bold;
    max-width: 1062px;
  }
  .features-comparison h3 {
    /* font-size: 41px; */
    line-height: 61.5px;
    font-weight: bold;
    max-width: 1062px;
  }
  
  .features-comparison p {
    text-align: center;
    font-size: 20px;
    line-height: 35px;
    max-width: 1062px;
  }
  
  .features-comparison table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;
    text-align: left;
  }
  
  
  .features-comparison th {
    background-color: #fff;
    font-size: 32px;
    font-weight: bold;
    line-height: 41.9px;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 15px;
  }

  .features_comparison_first_column{
    background-color: white;
    font-size: 24px;
    font-weight: bold;
    line-height: 30px;
    text-align: left;
    max-width: 190px;
  }

  .features_comparison_second_column{
    background-color: #E6F8FE;
    font-size: 18px;
    line-height: 26px;
  }
  
  .features-comparison td {
    padding: 30px;
  }

  .features_comparison_other_column {
    background-color: white;
    font-size: 18px;
    line-height: 26px;
  }

  