.waiting_form div,
.waiting_form div label,
.waiting_form div input,
.waiting_form div textarea,
.waiting_form button,
.waiting_form div select
 {
    width: 100%;
    text-align: left;
    margin: 2px auto;

}

.waiting_form div input,
.waiting_form div select
{
    border-radius: 5px;
    height: 35px;
    margin-bottom: 10px;
    color: black;
    padding: 2px;
}

.waiting_form div textarea {
    border-radius: 5px;
    height: 100px;
    margin-bottom: 10px;
    color: black;
    padding: 2px;
}

.waiting_form button {
    background-color: #D0FC54;
    color: black;
    text-align: center;
    padding: 2px;
    border-radius: 5px;
    border: 1px solid black;
    margin-top: 20px;
}

.waiting_form button:hover {
    background-color: white;
    color: black;
}


